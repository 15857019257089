import React, { Component, Suspense, useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import './scss/style.scss'
import ProtectedRoute from './components/Auth/ProtectedRoute'
import LoginRoute from './components/Auth/LoginRoute'
import { useSelector } from 'react-redux';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const AdminLayout = React.lazy(() => import('./layout/AdminLayout'))
const ManagerLayout = React.lazy(() => import('./layout/ManagerLayout'))
const GatekeeperLayout = React.lazy(() => import('./layout/GatekeeperLayout'))
const EmployeeLayout = React.lazy(() => import('./layout/EmployeeLayout'))
const UserLayout = React.lazy(() => import('./layout/UserLayout'))

// Pages
const Home = React.lazy(() => import('./views/pages/Home'))
const Contact = React.lazy(() => import('./views/pages/Contact'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Services = React.lazy(() => import('./views/pages/Services'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {

  let { data } = useSelector((state) => state.auth);
  let user = data;

  const UsersRoute = () => {
    if (user?.role == 'admin' || user?.role == 'hr') {
      return <ProtectedRoute><AdminLayout /></ProtectedRoute>;
    } else if (user?.role == 'manager') {
      return <ProtectedRoute><ManagerLayout /></ProtectedRoute>;
    } else if (user?.role == 'gatekeeper') {
      return <ProtectedRoute><GatekeeperLayout /></ProtectedRoute>;
    } else if (user?.role == 'employee') {
      return <ProtectedRoute><EmployeeLayout /></ProtectedRoute>;
    } else {
      return <Navigate to="/login" />;
    }
  };

  return (
    <Router>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/" name="Page Home" element={<Home />} />
          <Route exact path="/home" name="Page Home" element={<Home />} />
          <Route exact path="/contact" name="Page Contact" element={<Contact />} />

          <Route 
            exact path="/login" 
            name="Login Page" 
            element={
              <LoginRoute>
                <Login />
              </LoginRoute>
            } 
          />
          <Route 
            exact path="/register" 
            name="Register Page" 
            element={
              <LoginRoute>
                <Register />
              </LoginRoute>
            } 
          />
          <Route exact path="/services" name="Services" element={<Services />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          {/* <Route
            path="*"
            element={
              userType == 'admin' ? (
                <ProtectedRoute>
                  <SuperAdminLayout />
                </ProtectedRoute>
              ) : (
                <ProtectedRoute>
                  <AdminLayout />
                </ProtectedRoute>
              )
            }
          /> */}
          <Route path="*" element={<UsersRoute />} ></Route>
        </Routes>
      </Suspense>
    </Router>
  )
}

export default App
