import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from 'react-redux';

const LoginRoute = ({ user, children }) => {
    // let isAuthenticated = localStorage.getItem("isAuthenticated");
    let data = useSelector((state) => state.auth);
    let role = data?.data?.role;
    if (role) {
        if(role == 'admin' || role == 'hr') {
            return <Navigate to="/services" />;// <Navigate to="/admin/dashboard" replace />;
        } else if(role) {
            return <Navigate to="/services" />;// <Navigate to="/dashboard" replace />;
        } else {
            return <Navigate to="/login" replace />;
        } 
    }
    return children;
};

export default LoginRoute;
