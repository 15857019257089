import { takeEvery, put } from 'redux-saga/effects'
import { LOGOUT, SET_LOGIN_DATA } from '../constants/constant';
import env from '../../env';

function* logoutUser(action) {
  let data = []
  yield put({ type: SET_LOGIN_DATA, data })
}

function* logoutSaga(cred) {
  yield takeEvery(LOGOUT, logoutUser)
}

export default logoutSaga;