import { SET_LOGIN_DATA } from "../constants/constant"



export const auth = (data = [], action) => {
    switch (action.type) {
            case SET_LOGIN_DATA:
                console.warn("SET_LOGIN_DATA condition ", action)
                return action.data
        default:
            return data
    }
}